import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>Features</Subtitle>
      <SectionTitle>For your followers</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Exercise Library</FeatureTitle>
          <FeatureText>
            A complete base of exercise information for followers.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Native Mobile</FeatureTitle>
          <FeatureText>
            You'll be able to offer a true mobile app experience, with
            offline support.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>White-labeled</FeatureTitle>
          <FeatureText>
            The app will be availabile with your unique colors, splash screen, and icon.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Marketing page</FeatureTitle>
          <FeatureText>
            You'll receive a custom marketing page to link to from your posts.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
    <StyledContainer>
      <SectionTitle>For the trainer</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Routine Builder</FeatureTitle>
          <FeatureText>
            Instead of publishing routines in Instagram, build high-quality
            structured workouts for your followers.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Individualized Plans</FeatureTitle>
          <FeatureText>
            Offer personalized workouts to each client.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Other Content</FeatureTitle>
          <FeatureText>
            Contribute to the private feed in your app with other content
            just for your subscribers, like meal plans.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Monetization</FeatureTitle>
          <FeatureText>
            Subscription service signup available on your custom
            marketing page. Set your own price.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
